import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { DEFAULT_COMPANY_CONFIG } from '../config/app.config';

export default function Copyright(props) {
  const { ...rest } = props;

  return (
    <Box {...rest}>
      <Typography variant="body2" color="textPrimary" align="center">
        <FormattedMessage id="copyright" />
        {' © '}
        <Link color="inherit" href={window?.companyConfig?.url || DEFAULT_COMPANY_CONFIG['url']}>
          {window?.companyConfig?.name || DEFAULT_COMPANY_CONFIG['name']}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
		{' Version 1.0.0'}
      </Typography>
    </Box>
  );
}
